import React from "react";
import { AppProps } from "next/app";
import Head from "next/head";
import "@template/styles/globals.css";
import { Analytics } from "@vercel/analytics/react";

const App: React.FC<AppProps> = ({ Component, pageProps }) => (
  // Note that theme is top level above layout
  <>
    <Head>
      <script
        defer
        data-domain="oooooooooooooooooooorange.com"
        src="/potato/js/script.js"
        data-api="/potato/api/event"
      />
    </Head>
    <Component {...pageProps} />
    <Analytics />
  </>
);

export default App;
